import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import dracula from 'react-syntax-highlighter/dist/cjs/styles/hljs/dracula'
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
import { useState } from 'react'

SyntaxHighlighter.registerLanguage('css', css)

export function LineHeightExample ({ gotoPage }) {
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <div className="example">
      <h2>LINE HEIGHT</h2>
      <SyntaxHighlighter language="css" style={dracula} className={'code'}>
        {`
.line-height-container {
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  width: 100%;
}

`}
      </SyntaxHighlighter>
      <div>
        <p className={'text-pro'}>Pros:</p>
        <ul>
          <li>Simple and straightforward for single lines of text.</li>
          <li>No need for additional HTML elements or complex CSS rules.</li>
        </ul>
        <p className={'text-con'}>Cons:</p>
        <ul>
          <li>Only suitable for single-line content. If the content wraps to a new line, it will not appear centered.</li>
          <li>Adjusting the container size or content dynamically requires careful management of the line-height to maintain vertical centering.</li>
        </ul>
      </div>
      <div className={'button'} onClick={() => setPopupVisible(true)}>The real example!
      </div>
      <div className={'nav'}>
        <div className="next" onClick={() => gotoPage(3)}>← Absolute transform</div>
      </div>
      {popupVisible && <div className={'demo'}>
        <div className={'line-height-container'} onClick={() => setPopupVisible(false)}>
            <h3>I am just a poor inline element.</h3>
        </div>
      </div>}
    </div>
  )
}
