import { FlexExample } from './FlexExample'
import { useState } from 'react'
import { GridExample } from './GridExample'
import { TableExample } from './TableExample'
import { AbsoluteExample } from './AbsoluteExample'
import { AbsoluteMarginExample } from './AbsoluteMarginExample'
import { LineHeightExample } from './LineHeightExample'

function App () {

  const [page, setPage] = useState(0)

  function gotoPage (nextPage) {
    setPage(nextPage)
  }

  function renderPage () {
    const pages = {
      0: <FlexExample gotoPage={gotoPage}/>,
      1: <GridExample gotoPage={gotoPage}/>,
      2: <TableExample gotoPage={gotoPage}/>,
      3: <AbsoluteExample gotoPage={gotoPage}/>,
      4: <AbsoluteMarginExample gotoPage={gotoPage}/>,
      5: <LineHeightExample gotoPage={gotoPage}/>
    }
    return pages[page]
  }

  return (
    <div className="App">
      <div className="landscape_container">
        <h2>Fancy rotating your device, pal?</h2>
      </div>
      <div className={'wrapper'}>
        <h1 className={'heading'}>HOW TO CENTER A DIV</h1>
        {renderPage()}
        <div className={'footer'}>Made with &lt;divs/&gt; in Barcelona.</div>
      </div>
    </div>
  )
}

export default App


