import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import dracula from 'react-syntax-highlighter/dist/cjs/styles/hljs/dracula'
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
import { useState } from 'react'

SyntaxHighlighter.registerLanguage('css', css)

export function FlexExample ({ gotoPage }) {
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <div className="example">
      <h2>FLEX</h2>
      <SyntaxHighlighter language="css" style={dracula} className={'code'}>
        {`
 .flex-container {           
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
 }
 
`}
      </SyntaxHighlighter>
      <div>
        <p className={'text-pro'}>Pros:</p>
        <ul>
          <li>Simple and straightforward.</li>
          <li>Easily centers multiple items.</li>
          <li>Responsive and adaptable to dynamic content sizes.</li>
        </ul>
        <p className={'text-con'}>Cons:</p>
        <ul>
          <li>Requires a modern browser (though most current browsers support it).</li>
          <li>Overhead of understanding flexbox properties for beginners.</li>
        </ul>
      </div>
      <div className={'button'} onClick={()=> setPopupVisible(true)}>The real example!</div>
      <div className={'nav'}>
        <div className="next" onClick={() => gotoPage(1)}>Grid →</div>
      </div>
      {popupVisible && <div className={'demo'}>
        <div className={'flex-container'} onClick={()=> setPopupVisible(false)}>
          <div className={'flex-content'}>
            <h3>Hey mom, I am in the center!</h3>
            <p>Open the element inspector and check styles</p>
          </div>
        </div>
      </div>}
    </div>
  )
}
