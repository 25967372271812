import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import dracula from 'react-syntax-highlighter/dist/cjs/styles/hljs/dracula'
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
import { useState } from 'react'

SyntaxHighlighter.registerLanguage('css', css)

export function TableExample ({ gotoPage }) {
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <div className="example">
      <h2>TABLE CELL</h2>
      <SyntaxHighlighter language="css" style={dracula} className={'code'}>
        {`
 .table-container {           
   display: table-cell;
   text-align: center;
   vertical-align: middle;
   height: 100vh;
   width: 100vw;
 }
 
 .table-content {
   width: max-content;
   margin: auto;
 }
 
`}
      </SyntaxHighlighter>
      <div>
        <p className={'text-pro'}>Pros:</p>
        <ul>
          <li>Can be useful in legacy projects or where grid and flexbox are not options.</li>
        </ul>
        <p className={'text-con'}>Cons:</p>
        <ul>
          <li>Less flexible and more convoluted than modern methods.</li>
          <li>Requires changing the display property which might interfere with other styling.</li>
        </ul>
      </div>
      <div className={'button'} onClick={() => setPopupVisible(true)}>The real example!
      </div>
      <div className={'nav'}>
        <div className="next" onClick={() => gotoPage(1)}>← Grid</div>
        <div className="next" onClick={() => gotoPage(3)}>Absolute transform →</div>
      </div>
      {popupVisible && <div className={'demo'}>
        <div className={'table-container'} onClick={() => setPopupVisible(false)}>
          <div className={'table-content'}>
            <h3>Hey mom, I am in the center!</h3>
            <p>Open the element inspector and check styles</p>
          </div>
        </div>
      </div>}
    </div>
  )
}
