import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import dracula from 'react-syntax-highlighter/dist/cjs/styles/hljs/dracula'
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
import { useState } from 'react'

SyntaxHighlighter.registerLanguage('css', css)

export function AbsoluteMarginExample ({ gotoPage }) {
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <div className="example">
      <h2>ABSOLUTE POSITIONING WITH MARGIN</h2>
      <SyntaxHighlighter language="css" style={dracula} className={'code'}>
        {`
 .transform_margin-container {           
   position: relative;
   height: 100vh;
   width: 100%;
 }
 
 .transform_margin-content {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   margin: auto;
   width: 50%; /* or any specific width */
   height: 50%; /* or any specific height */
 }
 
`}
      </SyntaxHighlighter>
      <div>
        <p className={'text-pro'}>Pros:</p>
        <ul>
          <li>Works well when you know the width and height of the centered content.</li>
          <li>Compatible with older browsers.</li>
        </ul>
        <p className={'text-con'}>Cons:</p>
        <ul>
          <li>Requires that width and height be set, which might not be dynamic or responsive.</li>
        </ul>
      </div>
      <div className={'button'} onClick={() => setPopupVisible(true)}>The real example!
      </div>
      <div className={'nav'}>
        <div className="next" onClick={() => gotoPage(3)}>← Absolute transform</div>
        <div className="next" onClick={() => gotoPage(5)}>Line height →</div>
      </div>
      {popupVisible && <div className={'demo'}>
        <div className={'transform_margin-container'} onClick={() => setPopupVisible(false)}>
          <div className={'transform_margin-content'}>
            <h3>Hey mom, I am in the center!</h3>
            <p>Open the element inspector and check styles</p>
          </div>
        </div>
      </div>}
    </div>
  )
}
