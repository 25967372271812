import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import dracula from 'react-syntax-highlighter/dist/cjs/styles/hljs/dracula'
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
import { useState } from 'react'

SyntaxHighlighter.registerLanguage('css', css)

export function GridExample ({ gotoPage }) {
  const [popupVisible, setPopupVisible] = useState(false)
  return (
    <div className="example">
      <h2>GRID</h2>
      <SyntaxHighlighter language="css" style={dracula} className={'code'}>
        {`
 .grid-container {           
   display: grid;
   place-items: center;
   height: 100vh;
 }
 
`}
      </SyntaxHighlighter>
      <div>
        <p className={'text-pro'}>Pros:</p>
        <ul>
          <li>Very concise and powerful for both aligning and laying out elements.</li>
          <li>Works well with complex layouts and responsive designs.</li>
        </ul>
        <p className={'text-con'}>Cons:</p>
        <ul>
          <li>Requires understanding of the CSS grid layout, which might be overkill for simple
            tasks.
          </li>
          <li>Not supported in very old browsers.</li>
        </ul>
      </div>
      <div className={'button'} onClick={() => setPopupVisible(true)}>The real example!
      </div>
      <div className={'nav'}>
        <div className="next" onClick={() => gotoPage(0)}>← Flex</div>
        <div className="next" onClick={() => gotoPage(2)}>Table cell →</div>
      </div>
      {popupVisible && <div className={'demo'}>
        <div className={'grid-container'} onClick={() => setPopupVisible(false)}>
          <div className={'grid-content'}>
            <h3>Hey mom, I am in the center!</h3>
            <p>Open the element inspector and check styles</p>
          </div>
        </div>
      </div>}
    </div>
  )
}
