import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import dracula from 'react-syntax-highlighter/dist/cjs/styles/hljs/dracula'
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
import { useState } from 'react'

SyntaxHighlighter.registerLanguage('css', css)

export function AbsoluteExample ({ gotoPage }) {
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <div className="example">
      <h2>ABSOLUTE POSITIONING WITH TRANSFORM</h2>
      <SyntaxHighlighter language="css" style={dracula} className={'code'}>
        {`
 .transform-container {           
   position: relative;
   height: 100vh;
   width: 100%;
 }
 
 .transform-content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }
 
`}
      </SyntaxHighlighter>
      <div>
        <p className={'text-pro'}>Pros:</p>
        <ul>
          <li>Offers pixel-perfect control.</li>
          <li>Works in almost all browsers, including older ones.</li>
        </ul>
        <p className={'text-con'}>Cons:</p>
        <ul>
          <li>Can disrupt the normal document flow.</li>
          <li>Might overlap other elements if not handled properly.</li>
        </ul>
      </div>
      <div className={'button'} onClick={() => setPopupVisible(true)}>The real example!
      </div>
      <div className={'nav'}>
        <div className="next" onClick={() => gotoPage(2)}>← Table</div>
        <div className="next" onClick={() => gotoPage(4)}>Absolute margin →</div>
      </div>
      {popupVisible && <div className={'demo'}>
        <div className={'transform-container'} onClick={() => setPopupVisible(false)}>
          <div className={'transform-content'}>
            <h3>Hey mom, I am in the center!</h3>
            <p>Open the element inspector and check styles</p>
          </div>
        </div>
      </div>}
    </div>
  )
}
